
const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class UserUseCases {

    async login(username, password){
        const response = await fetch(`${baseURL}/api/user/login/${encodeURIComponent(username)}/${encodeURIComponent(password)}`, {method: 'get'});
        return await response.json();
    }

    async getAll(){
        const response = await fetch(`${baseURL}/api/user/list`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
        });
        return data;
    }
}