import React, {useState, useEffect} from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';

import Copyright from './components/Copyright';
import Menu from './components/Menu';
import TitleBar from './components/TitleBar';

import AppointmentUseCases from '../domain/usecases/AppointmentUseCases';
import ClientUseCases from '../domain/usecases/ClientUseCases';
import ContractUseCases from '../domain/usecases/ContractUseCases';
import InterviewUseCases from '../domain/usecases/InterviewUseCases';
import OrderUseCases from '../domain/usecases/OrderUseCases';
import ServiceOrderUseCases from '../domain/usecases/ServiceOrderUseCases';
import WorkerUseCases from '../domain/usecases/WorkerUseCases';

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

const since = new Date();
since.setDate(1);
const today = new Date();
today.setHours(0, 0, 0, 0);
const until = new Date();

//Chart

// Generate Sales Data
function createChartData(time, amount) {
  return { time, amount };
}
  
function Chart() {
  const theme = useTheme();
  const [data, setData] = useState([
    createChartData('00:00', undefined),
    createChartData('09:00', undefined),
    createChartData('10:00', undefined),
    createChartData('11:00', undefined),
    createChartData('12:00', undefined),
    createChartData('14:00', undefined),
    createChartData('15:00', undefined),
    createChartData('16:00', undefined),
    createChartData('17:00', undefined),
    createChartData('18:00', undefined),
    createChartData('24:00', undefined),
  ]);
  
  const todayString = getDateString(today);
  const today09 = new Date(todayString + " 09:00:00");
  const today10 = new Date(todayString + " 10:00:00");
  const today11 = new Date(todayString + " 11:00:00");
  const today12 = new Date(todayString + " 12:00:00");
  const today14 = new Date(todayString + " 14:00:00");
  const today15 = new Date(todayString + " 15:00:00");
  const today16 = new Date(todayString + " 16:00:00");
  const today17 = new Date(todayString + " 17:00:00");
  const today18 = new Date(todayString + " 18:00:00");

  let count09 = 0;
  let count10 = 0;
  let count11 = 0;
  let count12 = 0;
  let count14 = 0;
  let count15 = 0;
  let count16 = 0;
  let count17 = 0;
  let count18 = 0;
  let count24 = 0;

  const countData = (data)=>{
    if (data.created_at <= today09){
      count09++;
    } else if (data.created_at > today09 && data.created_at <= today10) {
      count10++;
    } else if (data.created_at > today10 && data.created_at <= today11) {
      count11++;
    } else if (data.created_at > today11 && data.created_at <= today12) {
      count12++;
    } else if (data.created_at > today12 && data.created_at <= today14) {
      count14++;
    } else if (data.created_at > today14 && data.created_at <= today15) {
      count15++;
    } else if (data.created_at > today15 && data.created_at <= today16) {
      count16++;
    } else if (data.created_at > today16 && data.created_at <= today17) {
      count17++;
    } else if (data.created_at > today17 && data.created_at <= today18) {
      count18++;
    } else {
      count24++;
    }
  };

  useEffect(()=>{
    (async()=>{
      count09 = 0;
      count10 = 0;
      count11 = 0;
      count12 = 0;
      count14 = 0;
      count15 = 0;
      count16 = 0;
      count17 = 0;
      count18 = 0;
      count24 = 0;
      const appointments = await new AppointmentUseCases().getAll(getDateTimeString(today), getDateTimeString(until), 1);
      const contracts = await new ContractUseCases().getAll(getDateTimeString(today), getDateTimeString(until), 1);
      const interviews = await new InterviewUseCases().getAll(getDateTimeString(today), getDateTimeString(until), 1);
      const orders = await new OrderUseCases().getAll(getDateTimeString(today), getDateTimeString(until), 1);
      const serviceOrders = await new ServiceOrderUseCases().getAll(getDateTimeString(today), getDateTimeString(until), 1);
      appointments.forEach(countData);
      contracts.forEach(countData);
      interviews.forEach(countData);
      orders.forEach(countData);
      serviceOrders.forEach(countData);
      count10 += count09;
      count11 += count10;
      count12 += count11;
      count14 += count12;
      count15 += count14;
      count16 += count15;
      count17 += count16;
      count18 += count17;
      count24 += count18;
      const currentDate = new Date();
      setData([
        createChartData('00:00', 0),
        createChartData('09:00', count09),
        createChartData('10:00', currentDate >= today09 ? count10 : undefined),
        createChartData('11:00', currentDate >= today10 ? count11 : undefined),
        createChartData('12:00', currentDate >= today11 ? count12 : undefined),
        createChartData('14:00', currentDate >= today12 ? count14 : undefined),
        createChartData('15:00', currentDate >= today14 ? count15 : undefined),
        createChartData('16:00', currentDate >= today15 ? count16 : undefined),
        createChartData('17:00', currentDate >= today16 ? count17 : undefined),
        createChartData('18:00', currentDate >= today17 ? count18 : undefined),
        createChartData('24:00', currentDate >= today18 ? count24 : undefined)
      ]);
    })();
  }, []);
  return (
    <React.Fragment>
      <Title>Interacciones de Hoy</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

// Deposits

function preventDefault(event) {
    event.preventDefault();
}
  
const useStylesDeposits = makeStyles({
  depositContext: {
    flex: 1,
  },
});
  
function Deposits() {
  const classes = useStylesDeposits();
  const [total, setTotal] = useState(0.0);

  useEffect(()=>{
    (async()=>{
      const serviceOrderUseCases = new ServiceOrderUseCases();
      const serviceOrders = await serviceOrderUseCases.getAll(getDateTimeString(since), getDateTimeString(until), 1);
      let temp = 0;
      serviceOrders.forEach((serviceOrder)=>{
        temp += parseFloat(serviceOrder.paymentAmount);
      });
      setTotal(temp);
    })();
  }, []);
  return (
    <React.Fragment>
      <Title>Cobros Recientes</Title>
      <Typography component="p" variant="h4">
        S/ {total.toFixed(2)}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        desde el {since.toLocaleDateString("es-PE", { year: 'numeric', month: 'long', day: 'numeric'})}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          Ver Ordenes de Servicio
        </Link>
      </div>
    </React.Fragment>
  );
}

//Title

function Title(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}
  
  /*Title.propTypes = {
    children: PropTypes.node,
  };*/

//Orders  
const useStylesOrders = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  }
}));
  
function Orders() {
  const classes = useStylesOrders();
  const [rows, setRows] = useState([]);

  useEffect(()=>{
    (async()=>{
      const contractUseCases = new ContractUseCases();
      setRows(await contractUseCases.getAll(getDateTimeString(since), getDateTimeString(until), 1, 5));
    })();
  }, []);
  return (
    <React.Fragment>
      <Title>Convenios Recientes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Empleador</TableCell>
            <TableCell>Trabajador</TableCell>
            <TableCell>Registrado por</TableCell>
            <TableCell align="right">Fecha Registro</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{getDateString(row.signingDate)}</TableCell>
              <TableCell>{row.interview.service_order.order.client.firstName} {row.interview.service_order.order.client.lastName}</TableCell>
              <TableCell>{row.interview.worker.firstName} {row.interview.worker.lastName}</TableCell>
              <TableCell>{row.interview.attending_user.firstName} {row.interview.attending_user.lastName}</TableCell>
              <TableCell align="right">{getDateString(row.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="/contracts">
          Ver convenios del mes
        </Link>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));

export default function MainPage() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TitleBar title={"Inicio"} open={open} handleDrawerOpen={handleDrawerOpen} />
      <Menu open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}