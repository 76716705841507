import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import LoginPage from './screens/LoginPage';
import MainPage from './screens/MainPage';
import AppointmentsPage from './screens/AppointmentsPage';
import WorkersPage from './screens/WorkersPage';
import ClientsPage from './screens/ClientsPage';
import OrdersPage from './screens/OrdersPage';
import ServiceOrdersPage from './screens/ServiceOrdersPage';
import InterviewsPage from './screens/InterviewsPage';
import ContractsPage from './screens/ContractsPage';
import { pink } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: pink[900]
    },
    primary: {
      main: pink[700]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

function App() {
  // console.log("location", window.location.pathname.substring(1));
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <main>
          <Switch>
            <Route exact path="/" render={props => (<LoginPage {...props} />)} />
            <Route exact path="/main" render={props => (<MainPage {...props} />)} />
            <Route exact path="/appointments" render={props => (<AppointmentsPage {...props} />)} />
            <Route exact path="/clients" render={props => (<ClientsPage {...props} />)} />
            <Route exact path="/workers" render={props => (<WorkersPage {...props} />)} />
            <Route exact path="/orders" render={props => (<OrdersPage {...props} />)} />
            <Route exact path="/serviceorders" render={props => (<ServiceOrdersPage {...props} />)} />
            <Route exact path="/interviews" render={props => (<InterviewsPage {...props} />)} />
            <Route exact path="/contracts" render={props => (<ContractsPage {...props} />)} />
          </Switch>
        </main>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;