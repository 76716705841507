import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import ServiceOrderUseCases from '../../domain/usecases/ServiceOrderUseCases';
import WorkerUseCases from '../../domain/usecases/WorkerUseCases';
import UserUseCases from '../../domain/usecases/UserUseCases';
import InterviewUseCases from '../../domain/usecases/InterviewUseCases';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  if (date.getFullYear && date.getMonth && date.getDate){
    return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
  }else{
    return date;
  }
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

export default function InterviewModal({show, setShow, interview}) {
  console.log(interview);
  const classes = useStyles();

  const [serviceOrders, setServiceOrders] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const loadServiceOrders = async ()=>{
    const serviceOrderUseCases = new ServiceOrderUseCases();
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    const since = new Date(getDateString(firstDayOfMonth) + " 00:00:00");
    const until = new Date(getDateString(new Date()) + " 00:00:00");
    console.log(since, until);
    until.setDate(until.getDate()+1);
    //replace with unused service orders
    let serviceOrders = await serviceOrderUseCases.getAll(getDateTimeString(since), getDateTimeString(until), 1);
    if (interview){
      //serviceOrders = [interview.serviceOrder].concat(serviceOrders);
    }
    setServiceOrders(serviceOrders);
  };

  const loadWorkersAndUsers = async ()=>{
    const workerUseCases = new WorkerUseCases();
    const userUseCases = new UserUseCases();
    //replace with non working workers
    setWorkers(await workerUseCases.getAll());
    setUsers(await userUseCases.getAll());
  }

  useEffect(()=>{
    loadServiceOrders();
    if (interview){
      setSelectedDate(interview.start);
    }else{
      setSelectedDate(new Date());
    }
  }, [interview]);

  useEffect(()=>{
    loadWorkersAndUsers();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    setCurrentUserId(loggedUser.id);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (interview){
      data.id = interview.id;
    }
    console.log(data);
    const interviewUseCases = new InterviewUseCases();
    await interviewUseCases.save(data, currentUserId); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Entrevista</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos de la entrevista<br/>
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_service_order-label">Órden de Servicio</InputLabel>
              <Select 
                name="id_service_order" 
                defaultValue={interview ? interview.id_service_order : ""}
                required>
                {serviceOrders.map(serviceOrder=>(
                  <MenuItem key={serviceOrder.id} value={serviceOrder.id}>{serviceOrder.order.client.firstName} {serviceOrder.order.client.lastName} ({getDateString(serviceOrder.created_at)})</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_worker-label">Trabajador</InputLabel>
              <Select 
                name="id_worker" 
                defaultValue={interview ? interview.id_worker : ""}
                required>
                {workers.map(worker=>(
                  <MenuItem key={worker.id} value={worker.id}>{worker.firstName} {worker.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_attending_user-label">Empleado</InputLabel>
              <Select 
                name="id_attending_user" 
                defaultValue={interview ? interview.id_attending_user : currentUserId}
                required>
                {users.map(user=>(
                  <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel >Resultado de la entrevista</InputLabel>
              <Select name="id_interview_outcome" defaultValue={interview ? interview.id_interview_outcome : ""} required>
                <MenuItem value="1">Aprobada</MenuItem>
                <MenuItem value="2">Desaprobada</MenuItem>
                <MenuItem value="3">Reserva</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="start"
                label="Fecha y hora de la entrevista cita"
                value={selectedDate}
                onChange={setSelectedDate}
                onError={console.log}
                format="yyyy-MM-dd HH:mm:ss"
              />
            </MuiPickersUtilsProvider>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="comments"
              label="Comentarios"
              type="text"
              fullWidth
              required
              defaultValue={interview ? interview.comments : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}