const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class ContractUseCases {
    async getAll(since=undefined, until=undefined, active=1, limit=undefined){
        const params = {since, until, active, limit};
        const response = await fetch(`${baseURL}/api/contract/list?${objectToQueryString(params)}`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
            item.startDate = new Date(item.startDate);
            item.signingDate = new Date(item.signingDate);
        });
        return data;
    }

    async save(interview, idUser){
        const response = await fetch(`${baseURL}/api/contract/save/${idUser}`, {
            method: 'post', 
            body: JSON.stringify(interview),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    async delete(interview){
        const response = await fetch(`${baseURL}/api/contract/delete/${interview.id}`, {method: 'delete'});
        return await response.json();
    }
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => obj[key] !== undefined ? key + '=' + obj[key] : undefined).filter(el => el!==undefined).join('&');
}