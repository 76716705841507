import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import ServiceOrderUseCases from '../../domain/usecases/ServiceOrderUseCases';
import OrderUseCases from '../../domain/usecases/OrderUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  if (date.getFullYear && date.getMonth && date.getDate){
    return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
  }else{
    return date;
  }
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

export default function ServiceOrderModal({show, setShow, serviceOrder}) {
  console.log(serviceOrder);
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const loadOrdersAndPaymentTypes = async ()=>{
    const orderUseCases = new OrderUseCases();
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    const since = new Date(getDateString(firstDayOfMonth) + " 00:00:00");
    const until = new Date(getDateString(new Date()) + " 00:00:00");
    console.log(since, until);
    until.setDate(until.getDate()+1);
    let orders = await orderUseCases.getAll(getDateTimeString(since), getDateTimeString(until), 1, 0);
    let selectedOrder = "";
    if (serviceOrder){
      setPaymentType(serviceOrder.id_payment_type);
      orders = [serviceOrder.order].concat(orders);
      selectedOrder = serviceOrder.order.id;
    }else{
      setPaymentType("");
    }
    setOrders(orders);
    setSelectedOrder(selectedOrder);
  };

  useEffect(()=>{
    loadOrdersAndPaymentTypes();
  }, [serviceOrder]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (serviceOrder){
      data.id = serviceOrder.id;
    }
    console.log(data);
    const serviceOrderUseCases = new ServiceOrderUseCases();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    await serviceOrderUseCases.save(data, loggedUser.id); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Órden de Servicio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos de la órden de servicio<br/>
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_order-label">Pedido</InputLabel>
              <Select 
                name="id_order" 
                value={selectedOrder}
                onChange={e=>setSelectedOrder(e.target.value)}
                required>
                {orders.map(order=>(
                  <MenuItem key={order.id} value={order.id}>{order.client.firstName} {order.client.lastName} ({getDateString(order.created_at)})</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel >Tipo de Pago</InputLabel>
              <Select name="id_payment_type" value={paymentType} onChange={(e)=>{setPaymentType(e.target.value)}} required>
                <MenuItem value="1">Efectivo</MenuItem>
                <MenuItem value="2">Transferencia Bancaria</MenuItem>
                <MenuItem value="3">Tarjeta</MenuItem>
                <MenuItem value="4">Otro</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="paymentAmount"
              label="Monto de Pago"
              type="text"
              fullWidth
              required
              defaultValue={serviceOrder ? serviceOrder.paymentAmount : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="paymentComments"
              label="Comentarios"
              type="text"
              fullWidth
              required
              defaultValue={serviceOrder ? serviceOrder.paymentComments : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}