const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class WorkerUseCases {
    async getAll(blacklisted, active=1){
        const params = {blacklisted, active};
        const response = await fetch(`${baseURL}/api/worker/list?${objectToQueryString(params)}`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.dob = new Date(item.dob);
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async save(worker, idUser){
        const response = await fetch(`${baseURL}/api/worker/save/${idUser}`, {
            method: 'post', 
            body: JSON.stringify(worker),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    async delete(worker){
        const response = await fetch(`${baseURL}/api/worker/delete/${worker.id}`, {method: 'delete'});
        return await response.json();
    }
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => obj[key] !== undefined ? key + '=' + obj[key] : undefined).filter(el => el!==undefined).join('&');
  }