import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InterviewUseCases from '../../domain/usecases/InterviewUseCases';
import ContractUseCases from '../../domain/usecases/ContractUseCases';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  if (date.getFullYear && date.getMonth && date.getDate){
    return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
  }else{
    return date;
  }
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

export default function ContractModal({show, setShow, contract}) {
  console.log(contract);
  const classes = useStyles();

  const [interviews, setInterviews] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [signingDate, setSigningDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const loadInterviews = async ()=>{
    const interviewUseCases = new InterviewUseCases();
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    const since = new Date(getDateString(firstDayOfMonth) + " 00:00:00");
    const until = new Date(getDateString(new Date()) + " 00:00:00");
    console.log(since, until);
    until.setDate(until.getDate()+1);
    //replace with unused service orders
    let interviews = await interviewUseCases.getAll(getDateTimeString(since), getDateTimeString(until), 1);
    if (contract){
      //interviews = [contract.interview].concat(interviews);
    }
    setInterviews(interviews);
  };

  useEffect(()=>{
    loadInterviews();
    if (contract){
      setSigningDate(contract.signingDate);
      setStartDate(contract.startDate)
    }else{
      setSigningDate(new Date());
      setStartDate(new Date());
    }
  }, [contract]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (contract){
      data.id = contract.id;
    }
    console.log(data);
    const contractUseCases = new ContractUseCases();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    await contractUseCases.save(data, loggedUser.id); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Convenio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos del convenio<br/>
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_interview-label">Entrevista</InputLabel>
              <Select 
                name="id_interview" 
                defaultValue={contract ? contract.id_interview : ""}
                required>
                {interviews.map(interview=>(
                  <MenuItem key={interview.id} value={interview.id}>{interview.service_order.order.client.firstName} {interview.service_order.order.client.lastName} ({getDateString(interview.start)})</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel >Estado del convenio</InputLabel>
              <Select name="id_contract_status" defaultValue={contract ? contract.id_contract_status : ""} required>
                <MenuItem value="1">Activo</MenuItem>
                <MenuItem value="2">Pendiente</MenuItem>
                <MenuItem value="3">Anulado</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="signingDate"
                label="Fecha y hora de la firma del convenio"
                value={signingDate}
                onChange={setSigningDate}
                onError={console.log}
                format="yyyy-MM-dd HH:mm:ss"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="startDate"
                label="Fecha y hora de inicio de labores"
                value={startDate}
                onChange={setStartDate}
                onError={console.log}
                format="yyyy-MM-dd HH:mm:ss"
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}