import React, {useState, useEffect} from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MUIExpansionPanel from '@material-ui/core/ExpansionPanel';
import MUIExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MUIExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import Copyright from './components/Copyright';
import Menu from './components/Menu';
import TitleBar from './components/TitleBar';
import Order from './modals/Order';
import OrderUseCases from './../domain/usecases/OrderUseCases';
import ClientUseCases from './../domain/usecases/ClientUseCases';

const ExpansionPanel = withStyles({
  root: {
    //border: '1px solid rgba(0, 0, 0, .125)',
    width: '500px',
    maxWidth: '500px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MUIExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    //backgroundColor: 'rgba(0, 0, 0, .03)',
    //borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    marginLeft: 0,
    paddingLeft: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
      padding: 0
    },
  },
  expanded: {},
})(MUIExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MUIExpansionPanelDetails);

function Title(props) {
    return (
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.children}
      </Typography>
    );
}
  
const useStylesOrders = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

function Orders() {
  const classes = useStylesOrders();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [filterDate, setFilterDate] = useState(new Date());
  const [filterPaymentComplete, setFilterPaymentComplete] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const [clients, setClients] = useState([]);

  const loadClients = async () => {
    const clientUseCases = new ClientUseCases();
    const clients = await clientUseCases.getAll( 0, 1 );
    setClients(clients);
  };

  /*const getClient = (idClient) => {
    let value = null;
    clients.forEach(client=>{
      if (client.id === idClient){
        value = client;
      }
    });
    return value;
  };*/

  useEffect(()=>{
    loadClients();
  }, []);

  const orderUseCases = new OrderUseCases();

  const loadOrders = async () => {
    const since = new Date(getDateString(filterDate) + " 00:00:00");
    const until = new Date(since);
    until.setDate(until.getDate()+1);
    setOrders(await orderUseCases.getAll(getDateTimeString(since), getDateTimeString(until), filterActive ? 0 : 1, filterPaymentComplete ? filterPaymentComplete : undefined));
  };

  useEffect(()=>{
    loadOrders();
  }, [showOrderModal, filterDate, filterActive]);

  return (
    <React.Fragment>
      <Container className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <Title>Pedidos</Title>
        </Grid>
        <Grid item>
          <Fab size="medium" color="secondary" aria-label="add" onClick={()=>{
            setOrder(null);
            setShowOrderModal(true);
          }}>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className="filter-summary"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <ExpandMoreIcon />
            <b>Filtros:</b> 
            Fecha:{getDateString(filterDate)}
            {filterActive?", Mostrar eliminados":""}
            {filterPaymentComplete?", Mostrar pagados":""}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                label="Fecha de inicio de pedidos"
                value={filterDate}
                onChange={setFilterDate}
                onError={console.log}
                format="yyyy-MM-dd"
              />
            </MuiPickersUtilsProvider>
            <br/>
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox checked={filterPaymentComplete} onChange={()=>setFilterPaymentComplete(!filterPaymentComplete)} />
              }
              label="Mostrar pagados"
            />
            <br/>
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox checked={filterActive} onChange={()=>setFilterActive(!filterActive)} />
              }
              label="Mostrar eliminados"
            />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Empleador</TableCell>
            <TableCell>Pago</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(row => {
            const client = row.client;//getClient(row.id_client);
            return (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" width={120}>{getDateString(row.created_at)}</TableCell>
              <TableCell>{client.firstName} {client.lastName} </TableCell>
              <TableCell component="th" scope="row" width={150}>{row.paymentComplete === 0 ? 'Pendiente' : 'Completo'}</TableCell>
              <TableCell component="th" scope="row" width={90}>
                <a href="#!" onClick={()=>{
                  setOrder(row);
                  setShowOrderModal(true);
                }}><EditIcon/></a> 
                <a href="#!" onClick={async()=>{
                  await orderUseCases.delete(row);
                  loadOrders();
                }}><DeleteIcon/></a>
              </TableCell>
            </TableRow>
          )
          })}
        </TableBody>
      </Table>
      </Container>
      <Order show={showOrderModal} setShow={setShowOrderModal} order={order} clients={clients} />
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function OrdersPage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TitleBar title={"Pedidos"} open={open} handleDrawerOpen={handleDrawerOpen} />
      <Menu open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}