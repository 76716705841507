import React, {useState, useEffect} from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MUIExpansionPanel from '@material-ui/core/ExpansionPanel';
import MUIExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MUIExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Attachments from './modals/WorkerAttachments';

import Copyright from './components/Copyright';
import Menu from './components/Menu';
import TitleBar from './components/TitleBar';
import Worker from './modals/Worker';
import WorkerUseCases from './../domain/usecases/WorkerUseCases';

const ExpansionPanel = withStyles({
  root: {
    //border: '1px solid rgba(0, 0, 0, .125)',
    width: '500px',
    maxWidth: '500px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MUIExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    //backgroundColor: 'rgba(0, 0, 0, .03)',
    //borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    marginLeft: 0,
    paddingLeft: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MUIExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MUIExpansionPanelDetails);

function Title(props) {
    return (
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.children}
      </Typography>
    );
}
  
const useStylesOrders = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

function Workers() {
  const classes = useStylesOrders();
  const [showWorkerModal, setShowWorkerModal] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [worker, setWorker] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filterBlacklist, setFilterBlacklist] = useState(false);

  const workerUseCases = new WorkerUseCases();

  const loadWorkers = async () => {
    setWorkers(await workerUseCases.getAll(filterBlacklist ? 1 : 0, filterActive ? 0 : 1));
  };

  useEffect(()=>{
    loadWorkers();
  }, [showWorkerModal]);

  useEffect(()=>{
    loadWorkers();
  }, [filterActive]);

  useEffect(()=>{
    loadWorkers();
  }, [filterBlacklist]);

  return (
    <React.Fragment>
      <Container className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <Title>Trabajadores</Title>
        </Grid>
        <Grid item>
          <Fab size="medium" color="secondary" aria-label="add" onClick={()=>{
            setWorker(null);
            setShowWorkerModal(true);
          }}>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <ExpansionPanel>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><ExpandMoreIcon /> <b>Filtros:</b> 
            { filterActive && filterBlacklist ? 
                "Mostrar eliminados y lista negra" : 
                (filterActive ? 
                  "Mostrar eliminados": 
                  filterBlacklist ? 
                    "Mostrar lista negra" : 
                    "Ninguno")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox checked={filterActive} onChange={()=>setFilterActive(!filterActive)} />
              }
              label="Mostrar eliminados"
            />
            <br/>
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox checked={filterBlacklist} onChange={()=>setFilterBlacklist(!filterBlacklist)} />
              }
              label="Mostrar lista negra"
            />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Ap. Paterno</TableCell>
            <TableCell>Ap. Materno</TableCell>
            <TableCell>Nombres</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workers.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" width={120}>{row.lastName}</TableCell>
              <TableCell component="th" scope="row" width={120}>{row.motherMaidenName}</TableCell>
              <TableCell>{row.firstName}</TableCell>
              <TableCell component="th" scope="row" width={130}>{row.email}</TableCell>
              <TableCell component="th" scope="row" width={110}>
                <a href="#!" onClick={()=>{
                  setWorker(row);
                  setShowAttachmentsModal(true);
                }}><AttachFileIcon/></a> 
                <a href="#!" onClick={()=>{
                  setWorker(row);
                  setShowWorkerModal(true);
                }}><EditIcon/></a> 
                <a href="#!" onClick={async()=>{
                  await workerUseCases.delete(row);
                  loadWorkers();
                }}><DeleteIcon/></a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Container>
      <Worker show={showWorkerModal} setShow={setShowWorkerModal} worker={worker} />
      <Attachments show={showAttachmentsModal} setShow={setShowAttachmentsModal} worker={worker} />
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function WorkersPage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TitleBar title={"Trabajadores"} open={open} handleDrawerOpen={handleDrawerOpen} />
      <Menu open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Clients */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Workers />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}